.table-search-input.table-search-input {
  width: 300px;
}

.search-icon {
  margin-right: 5px;
  font-size: 18px;
}

.filter-container {
  height: 80px;
}

span.ant-tag-close-icon {
  font-size: 14px !important;
  transform: translateY(1px);
  margin-left: 4px !important;
  color: #289aca !important;
}

.ant-pagination-options {
  margin-right: 20px;
}

.ant-collapse-header {
  align-items: center !important;
}

.ant-collapse.ant-collapse-icon-position-start {
  background-color: transparent !important;
}

.ant-collapse-expand-icon {
  display: none !important;
}

.ant-collapse-header {
  padding: 0 !important;
  padding-bottom: 10px !important;
  cursor: default !important;
}

.ant-collapse.ant-collapse-icon-position-start {
  border: none !important;
}

.ant-collapse-item {
  margin-top: 5px;
  border-bottom: none !important;
}

.ant-collapse-content.ant-collapse-content-active {
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  align-items: center;
}

.table-filter-item {
    width: 265px;
    margin-bottom: 10px;
}

.table-filter-item-rangepicker {
    width: 350px;
}

.table-filter-dropdown {
    transform: translateY(calc(-100% - 40px)) !important;
}

