.warehouse {
  &-icon {
    color: #289aca;
    font-size: 18px;

    &-active {
      &:hover {
        transform: scale(1.25);
        transition-delay: 200ms;
        transition-property: transform;
        transition-duration: 500ms;
      }
    }
  }
}
